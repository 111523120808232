import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Score = ({ id }) => {
    const [score, setScore] = useState({});

    useEffect(() => {
        const getScore = async() => {
            const resp = await fetch(
                `https://simple-scores.gindi.workers.dev/api/scores/${id}`
            );
            const scoreResp = await resp.json();
            setScore(scoreResp);
        };

        getScore();
    }, [id]);

    if (!Object.keys(score).length) return <div />;

    return (
        <div>
            <h1>{score.home_team}</h1>
            <h1>{score.away_team}</h1>
            <p>
                <Link to="/">Go back</Link>
            </p>
        </div>
    );
};

export default Score;