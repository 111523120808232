import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Scores = () => {
    const [scores, setScores] = useState([]);

    useEffect(() => {
        const getScores = async() => {
            const resp = await fetch(
                "https://simple-scores.gindi.workers.dev/api/scores"
            );
            const scoresResp = await resp.json();
            setScores(scoresResp);
        };

        getScores();
    }, []);

    return (
        <div>
            <h1>Game Scores</h1>
            {scores.map((score) => (
                <div key={score.id}>
                    <h2>
                        {score.home_team}
                        {/* <Link to={`/scores/${score.id}`}>{score.title}</Link> */}
                    </h2>
                </div>
            ))}
        </div>
    );
};

export default Scores;