import { Router } from "@reach/router";

import Scores from './components/scores'
import Score from './components/score'

function App() {
  return (
    <Router>
      <Scores path="/" />
      <Score path="/scores/:id" />
    </Router>
  );
}

export default App;